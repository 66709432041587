import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { Button, CircularProgress, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { IBooking, IBookingVehicleGroup, IVehiclePriceGroup, IBookingSchedule, IInvoice, BookingStatus, ISwapRequestCreateInput, ISwapRequest, SwapRequestStatus, InvoiceStatus } from '../../../../reducers/bookings/types';
import { StyledTableCell, StyledTableRow } from '../../../StyledTable';
import { DateTimeFormat, DATE_TYPE, formatGraphQLErrorMessage, toCurrency } from '../../../utils';
import { getSignedUrl } from '../../../../utils/getSignedUrl';
import { DateTime as d, Settings } from "luxon";
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import { useHistory } from 'react-router-dom';
import { InvoiceType } from '../../NewBooking/Invoices/utils';
import { IBranch } from "../../../../reducers/website/types";
import GenerateSwapRequestDialog from "./GenerateSwapRequestDialog";
import { useMutation } from "@apollo/client";
import { CREATE_SUBSCRIPTION_SWAP_REQUEST } from "../../../../graphql/bookings/createSubscriptionVehicleSwapMutation";
import { useSnackBar } from "../../../SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../SnackbarWrapper/SnackbarWrapper";
import { ApolloError } from "apollo-boost";
import { UPDATE_SUBSCRIPTION_SWAP_REQUEST } from "../../../../graphql/bookings/updatesubscriptionSwapRequest";
import { getLocalizedBookingSyntex, getLocalizedDateFormat } from "../../../../utils/localized.syntex";
import { CANCEL_CONSUMER_SWAP_REQUEST } from "../../../../graphql/bookings/consumerCancelSwapRequestMutation";
import { BookingStatusText } from "../../utils";
import { CustomerType } from "../../../../reducers/auth/types";
import { DriversInformation } from "../Drivers/DriversInformation";

export interface IProps {
  booking: IBooking
}

const BookingSummary: React.FC<IProps> = (props) => {
  const history = useHistory()
  const snackbar = useSnackBar();
  const [booking, setBooking] = useState<IBooking>();
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const { currency, locale } = website.organisation;
  const { country } = website.organisation.address;
  const user = useSelector((state: IAppState) => state.authReducer.user);
  const [logoUrl, setLogoUrl] = useState<any>("");
  const branchData = website.branches;
  const [currentBranch, setCurrentBranch] = useState<IBranch>();
  const [openSwapRequestDialog, setOpenSwapRequestDialog] = useState<boolean>(false);
  const [selectedRequestToUpdate, setSelectedRequestToUpdate] = useState<ISwapRequest>();

  const [createSubscriptionVehicleSwapRequest, { data: bookingSubscriptionSwapData, loading: loadingCreateSwapRequest }] = useMutation(
    CREATE_SUBSCRIPTION_SWAP_REQUEST,
    {
      onCompleted: (data) => {
        if (data && data.consumerCreateSwapRequest) {
          setBooking(data.consumerCreateSwapRequest)
          snackbar({
            message: "Swap request created successfully",
            variant: SnackBarVariant.SUCCESS
          });
        }
        setOpenSwapRequestDialog(false)
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [updateSubscriptionVehicleSwapRequest, { data: updateBookingSubscriptionSwapData, loading: loadingUpdateBookingSwapData }] = useMutation(
    UPDATE_SUBSCRIPTION_SWAP_REQUEST,
    {
      onCompleted: (data) => {
        if (data && data.consumerUpdateSwapRequest) {
          setBooking(data.consumerUpdateSwapRequest)
          snackbar({
            message: "Swap request updated successfully",
            variant: SnackBarVariant.SUCCESS
          });
        }
        setOpenSwapRequestDialog(false)
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  const [cancelSwapRequest, { data: cancelSwapRequestData, loading: loadingCancelSwap }] = useMutation(
    CANCEL_CONSUMER_SWAP_REQUEST,
    {
      onCompleted: (data) => {
        if (data && data.consumerCancelSwapRequest) {
          setBooking(data.consumerCancelSwapRequest)
          snackbar({
            message: "Swap request cancelled successfully",
            variant: SnackBarVariant.SUCCESS
          });
        }
        setOpenSwapRequestDialog(false)
      },
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
      }
    }
  );

  useEffect(() => {
    if (props.booking) {
      const _bookingData: IBooking = props.booking;
      setBooking(_bookingData);

      const selectedBranch = branchData.find(
        (branch) => branch.id === _bookingData.branchId
      );
      setCurrentBranch(selectedBranch);
      if (selectedBranch) {
        Settings.defaultZone = selectedBranch.timeZone || "Europe/London";
      }
    }
    return () => {
      Settings.defaultZone = "Europe/London"
    }
  }, [props.booking]);

  useEffect(() => {
    const getLogoUpload = async () => {
      if (!website.organisation) {
        return;
      }
      const file = await getSignedUrl(website.organisation.logoUrl)
      setLogoUrl(file);
    };
    if (website.organisation && website.organisation.logoUrl) {
      getLogoUpload();
    }
  }, [website.organisation]);

  if (!booking) {
    return <CircularProgress size={20} thickness={5} />
  }

  const isBusinessCustomerBooking =
    booking.customerType === "business" &&
      booking.businessCustomer &&
      booking.businessCustomer.id !== ""
      ? true
      : false;

  const getMileageLimitText = (
    mileageLimit: number,
    rateType: string,
  ) => {
    switch (rateType) {
      case "hourly":
        return `${mileageLimit} ${currentBranch?.distanceUnit}s per hour`;
      case "daily":
        return `${mileageLimit} ${currentBranch?.distanceUnit}s per day`;
      case "weekly":
        return `${mileageLimit} ${currentBranch?.distanceUnit}s per week`;
      case "monthly":
        return `${mileageLimit} ${currentBranch?.distanceUnit}s per month`;
    }
  };

  const onCreateSwapRequest = (data: ISwapRequestCreateInput) => {
    if (data && data.dateForSwap) {
      if (data.id) {
        updateSubscriptionVehicleSwapRequest({
          variables: {
            data: {
              dateForSwap: data.dateForSwap,
              notes: data.notes,
              bookingId: booking.id,
              swapRequestId: data.id,
              vehicleAllocated: data.vehicleAllocated
            }
          }
        })
      } else {
        createSubscriptionVehicleSwapRequest({
          variables: {
            data: {
              dateForSwap: data.dateForSwap,
              notes: data.notes,
              bookingId: booking.id,
              vehicle: booking.activeSubscription.vehicle.id,
              vehicleAllocated: data.vehicleAllocated
            }
          }
        })
      }
    }
  }

  const onCancelSwapRequest = (swapRequestId: string) => {
    if (booking) {
      cancelSwapRequest({
        variables: {
          data: {
            bookingId: booking.id,
            swapRequestId
          }
        }
      })
    }
  }
  const hideVehicleDetails = [BookingStatus.REQUIRES_APPROVAL, BookingStatus.CONFIRMED].includes(booking.status);

  const renderVehicleDetails = () => {
    if (booking) {
      if (booking.isSubscription) {
        const vehicleSchedule = booking.currentBookingSchedules[0]
        const driver = booking.businessCustomer?.approvedDrivers.find((driver => driver.id === vehicleSchedule.driver?.id));
        return (
          <>
            {
              booking.isSubscription && booking.previousBookingSchedules?.length > 0 && (
                <Grid item xs={12} style={{ marginTop: 10 }}>
                  <div className={`${styles.box}`}>
                    <div className="font-medium semi-bold margin-bottom padding-bottom">Previous Vehicle Details</div>
                    <div style={{ margin: "0 -10px -10px -10px" }}>
                      {
                        booking.isSubscription &&
                        booking.previousBookingSchedules &&
                        booking.previousBookingSchedules.length > 0 && (
                          <>
                            <TableContainer style={{ maxHeight: "100%", }} className={`${booking.businessCustomer ? "row-hover" : ""}`}>
                              <Table stickyHeader aria-label="customized table">
                                <TableHead>
                                  <StyledTableRow>
                                    <StyledTableCell
                                      align="left"
                                    >
                                      <Typography>Subscription</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                    >
                                      <Typography>Registration</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                    >
                                      <Typography>Make/Model</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                    >
                                      <Typography>VIN</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell
                                      align="left"
                                    >
                                      <Typography>Mileage Limit</Typography>
                                    </StyledTableCell>
                                    {
                                      booking.customerType === CustomerType.BUSINESS && (
                                        <StyledTableCell align="left">
                                          <Typography>Driver Assigned</Typography>
                                        </StyledTableCell>
                                      )
                                    }
                                  </StyledTableRow> 
                                </TableHead>
                                <TableBody>
                                  {
                                    booking.previousBookingSchedules.map(vSchedule => {
                                      return (
                                        <>
                                          <TableRow>
                                            <StyledTableCell>{booking?.activeSubscription?.subscription?.name}</StyledTableCell>
                                            <StyledTableCell>{vSchedule.vehicle?.licencePlate}</StyledTableCell>
                                            <StyledTableCell>{vSchedule.vehicle?.make} {vSchedule.vehicle?.model}</StyledTableCell>
                                            <StyledTableCell>{vSchedule.vehicle?.vin}</StyledTableCell>
                                            <StyledTableCell>{booking?.activeSubscription?.unlimitedMileage ? "Unlimited" : booking?.activeSubscription?.mileageLimit}</StyledTableCell>
                                            {
                                              booking.customerType === CustomerType.BUSINESS && (
                                                <StyledTableCell>
                                                  {driver ? `${driver.firstName} ${driver.lastName}` : "N/A"}
                                                </StyledTableCell>
                                              )
                                            }
                                          </TableRow>
                                        </>
                                      )
                                    })
                                  }
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </>
                        )
                      }
                    </div>
                  </div>
                </Grid>
              )
            }
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <div className={`${styles.box}`}>
                <div className="font-medium semi-bold margin-bottom padding-bottom">Current Vehicle Details</div>
                <div style={{ margin: "0 -10px -10px -10px" }}>
                  <TableContainer style={{ maxHeight: "100%", }} className={`${booking.businessCustomer ? "row-hover" : ""}`}>
                    <Table stickyHeader aria-label="customized table">
                      <TableHead>
                        <StyledTableRow>
                          <StyledTableCell>Subscription</StyledTableCell>
                          <StyledTableCell>Make/Model</StyledTableCell>
                          <StyledTableCell>Registration</StyledTableCell>
                          <StyledTableCell>VIN</StyledTableCell>
                          {
                            booking.customerType === "business" && (
                              <StyledTableCell align="left">
                                <Typography>Driver Assigned</Typography>
                              </StyledTableCell>
                            )
                          }
                          <StyledTableCell>Mileage Limit</StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {
                          booking.activeSubscription && (
                            <StyledTableRow >
                              <StyledTableCell>{booking?.activeSubscription?.subscription?.name}</StyledTableCell>
                              <StyledTableCell>{`${booking?.activeSubscription?.vehicle?.make} ${booking?.activeSubscription?.vehicle?.model}`}</StyledTableCell>
                              {
                                ([BookingStatus.IN_PROGRESS, BookingStatus.COMPLETED].includes(booking.status)) ? (
                                  <StyledTableCell>{booking?.activeSubscription?.vehicle?.licencePlate}</StyledTableCell>
                                ) : <StyledTableCell>N/A</StyledTableCell>
                              }
                              <StyledTableCell>{booking?.activeSubscription?.vehicle.vin}</StyledTableCell>
                              {
                                booking.customerType === "business" && (
                                  <StyledTableCell>
                                    {driver ? `${driver.firstName} ${driver.lastName}` : "N/A"}
                                  </StyledTableCell>
                                )

                              }
                              <StyledTableCell>{booking?.activeSubscription?.unlimitedMileage ? "Unlimited" : booking?.activeSubscription?.mileageLimit}</StyledTableCell>
                            </StyledTableRow>
                          )
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </Grid>
          </>
        )
      }
      else {
        return (
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <div className={`${styles.box}`}>
              <div className="font-medium semi-bold margin-bottom padding-bottom">Current Vehicle Details</div>
              <div style={{ margin: "0 -10px -10px -10px" }}>

                <TableContainer style={{ maxHeight: "100%", }} className={`${booking.businessCustomer ? "row-hover" : ""}`}>
                  <Table stickyHeader aria-label="customized table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>Price Group</StyledTableCell>
                        {!hideVehicleDetails && <>
                          <StyledTableCell>Make/Model</StyledTableCell>
                          {[BookingStatus.IN_PROGRESS, BookingStatus.COMPLETED].includes(booking.status) && (
                          <StyledTableCell>Registration</StyledTableCell>)}
                          <StyledTableCell>VIN</StyledTableCell>
                          {
                            booking.customerType === "business" && (
                              <StyledTableCell align="left">
                                <Typography>Driver Assigned</Typography>
                              </StyledTableCell>
                            )
                          }
                        </>
                        }
                        <StyledTableCell>Mileage Limit</StyledTableCell>
                        {hideVehicleDetails && <StyledTableCell>Quantity</StyledTableCell>}
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {booking?.vehicleGroups?.map(
                        (vehicleGroup: IBookingVehicleGroup, idx: number) => {
                          const vg = vehicleGroup.vehicleGroup as IVehiclePriceGroup;
                          const basePrice = vg.basePrices.find(bp => bp.rateTypeName === booking.rateTypeName);

                          return (
                            !hideVehicleDetails ? <React.Fragment key={idx}>
                              {vehicleGroup?.activeVehicleSchedules?.map(
                                (vehicleSchedule: IBookingSchedule, index: number) => {
                                  const { vehicle, id, vehicleStatus } = vehicleSchedule;
                                  const driver = user?.businesscustomers?.approvedDrivers.find((driver => driver.id === vehicleSchedule.driver?.id));
                                  return (
                                    <React.Fragment key={index}>
                                      <StyledTableRow onClick={() => booking.businessCustomer && !vehicle.isGhostVehicle && history.push(`/account/vehicles/${vehicle.id}`)}>
                                        {index === 0 && (
                                          <StyledTableCell rowSpan={vehicleGroup.activeVehicleSchedules?.length}>
                                            {vehicleGroup.name ? vehicleGroup.name : vg.name}
                                          </StyledTableCell>
                                        )}
                                        <StyledTableCell>{`${vehicle?.make} ${vehicle.model}`}</StyledTableCell>
                                        {[BookingStatus.IN_PROGRESS, BookingStatus.COMPLETED].includes(booking.status) && (
                                          <StyledTableCell>
                                            <span>{vehicle.isGhostVehicle ? "To be assigned" : vehicle?.licencePlate.toUpperCase()}</span>
                                          </StyledTableCell>
                                        )}
                                        <StyledTableCell>
                                          {vehicle.vin || "N/A"}
                                        </StyledTableCell>
                                        {
                                          booking.customerType === "business" && (
                                            <StyledTableCell>
                                              {driver ? `${driver.firstName} ${driver.lastName}` : "N/A"}
                                            </StyledTableCell>
                                          )
                                        }
                                        {vehicleGroup && vehicleGroup.mileageLimit && basePrice ?
                                          <StyledTableCell>{getMileageLimitText(vehicleGroup.mileageLimit, basePrice.rateTypeName)}</StyledTableCell>
                                          : vehicleGroup?.unlimitedMileage ? <StyledTableCell>Unlimited Mileage</StyledTableCell>
                                            : <StyledTableCell>N/A</StyledTableCell>}
                                      </StyledTableRow>
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </React.Fragment> :
                              <StyledTableRow>
                                <StyledTableCell>
                                  {vehicleGroup.name ? vehicleGroup.name : vg.name}
                                </StyledTableCell>

                                {vehicleGroup && vehicleGroup.mileageLimit && basePrice ?
                                  <StyledTableCell>{getMileageLimitText(vehicleGroup.mileageLimit, basePrice.rateTypeName)}</StyledTableCell>
                                  : vehicleGroup?.unlimitedMileage ? <StyledTableCell>Unlimited Mileage</StyledTableCell>
                                    : <StyledTableCell>N/A</StyledTableCell>
                                }
                                <StyledTableCell>
                                  {vehicleGroup.vehicleIds.length}
                                </StyledTableCell>
                              </StyledTableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </Grid>
        )
      }

    }
  }

  return (
    <Grid container>
      <Grid item xs={12} md={3} style={{ display: "flex" }}>
        <div className={styles.card}>
          <div className="margin-bottom">
            <Typography variant="body1" className="opacity60 padding-bottom--small">{getLocalizedBookingSyntex(country)} Reference:</Typography>
            <Typography variant="body1" className="semi-bold">{booking.referenceNumber}</Typography>
          </div>
          {!website.organisation.closeGroupSharingEnabled && booking.firstInvoice?.status !== InvoiceStatus.DRAFT && (
            <>
          <div className="margin-bottom">
            <Typography variant="body1" className="opacity60 padding-bottom--small">Payment Status:</Typography>
            <Typography variant="body1" className="semi-bold">{booking.firstInvoice ? booking.firstInvoice.status : "N/A"}</Typography>
          </div>
          <div className="margin-bottom">
            <Typography variant="body1" className="opacity60 padding-bottom--small">Invoice Number:</Typography>
            <Typography variant="body1" className="semi-bold">{booking.firstInvoice ? booking.firstInvoice.invoiceRef : "N/A"}</Typography>
          </div>
          </>
          )}
          {isBusinessCustomerBooking && <div>
            <Typography variant="body1" className="opacity60 padding-bottom--small">PO Number:</Typography>
            <Typography variant="body1" className="semi-bold">{booking.poNumber || "N/A"}</Typography>
          </div>}
        </div>
      </Grid>
      <Grid item xs={12} md={5} style={{ display: "flex" }}>
        <div className={styles.card}>
          <div className="margin-bottom">
            <Typography variant="body1" className="opacity60 padding-bottom--small">{getLocalizedBookingSyntex(country)} Status:</Typography>
            <Typography variant="body1" className="semi-bold">{BookingStatusText[booking.status]}</Typography>
          </div>
          <div className="margin-bottom">
            <Typography variant="body1" className="opacity60 padding-bottom--small">
              {booking.pickupOtherLocation && booking.pickupOtherLocation.fullAddress
                ? "Delivery Address, Date & Time:"
                : "Pick-up Location, Date & Time:"}
            </Typography>
            <Typography variant="body1" className="semi-bold">
              {`${booking.pickupOtherLocation && booking.pickupOtherLocation.fullAddress
                ? booking.pickupOtherLocation.fullAddress
                : booking.pickupServiceLocation?.name || "N/A"
                }`}
            </Typography>
            <Typography variant="body1" className="semi-bold">
              {getLocalizedDateFormat(country, booking.pickupDateTime, DATE_TYPE.EXPANDED, booking.branch?.timeZone)}
            </Typography>
          </div>
          <div>
            <Typography variant="body1" className="opacity60 padding-bottom--small">
              {booking.dropoffOtherLocation && booking.dropoffOtherLocation.fullAddress
                ? "Collection Address, Date & Time:"
                : "Drop-off Location, Date & Time:"}
            </Typography>
            <Typography variant="body1" className="semi-bold">
              {`${booking.dropoffOtherLocation && booking.dropoffOtherLocation.fullAddress
                ? booking.dropoffOtherLocation.fullAddress
                : booking.dropoffServiceLocation?.name || "N/A"
                }`}
            </Typography>
            <Typography variant="body1" className="semi-bold">
              {booking.dropoffDateTime && getLocalizedDateFormat(country, booking.dropoffDateTime, DATE_TYPE.EXPANDED, booking.branch?.timeZone)}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={4} style={{ display: "flex" }}>
        <div className={styles.card} style={{ marginRight: 0 }}>
          <div className="margin-bottom">
            <Typography variant="body1" className="opacity60 padding-bottom--small">Customer:</Typography>
            <Typography variant="body1" className="semi-bold">
              {`${isBusinessCustomerBooking
                ? booking.businessCustomer?.businessName || ""
                : `${booking.customer?.firstName} ${booking.customer?.lastName}`
                }`}
            </Typography>
          </div>
          <div className="margin-bottom">
            <Typography variant="body1" className="opacity60 padding-bottom--small">Email Address:</Typography>
            <Typography variant="body1" className="semi-bold">
              {`${isBusinessCustomerBooking
                ? booking.businessCustomer?.billing.email
                : booking.customer
                  ? booking.customer.email
                  : ""
                }`}
            </Typography>
          </div>
          <div>
            <Typography variant="body1" className="opacity60 padding-bottom--small">Phone Number:</Typography>
            <Typography variant="body1" className="semi-bold">
              {`${isBusinessCustomerBooking
                ? booking.businessCustomer?.billing.phoneNumber.phone || ""
                : booking.customer
                  ? booking.customer.phoneNumber.phone
                  : ""
                }`}
            </Typography>
          </div>
        </div>
      </Grid>
      {
        renderVehicleDetails()
      }
      {
        booking.status === BookingStatus.IN_PROGRESS && booking?.activeSubscription?.swapsAllowed && (
          <Grid item xs={12} >
            <div className={styles.swapDetailsBox}>
              <Typography variant="h4">Vehicle Swap Details</Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setOpenSwapRequestDialog(true)
                  setSelectedRequestToUpdate({} as ISwapRequest)
                }}
                disabled={booking.activeSubscription.swapsAllowed === booking?.consumedSwaps?.length || booking?.swapRequests?.some(req => req.isActive)}
              >
                {(loadingCreateSwapRequest || loadingUpdateBookingSwapData) ? <CircularProgress size={"small"} /> : " Swap My Car"}
              </Button>
            </div>
            <div className={`${styles.box}`} style={{ marginTop: 15 }}>
              <div style={{ margin: "0 -10px -10px -10px" }}></div>
              <TableContainer component={Paper} >
                <Table aria-label="simple table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell >No. of swaps allowed</StyledTableCell>
                      <StyledTableCell >No. of swaps availed</StyledTableCell>
                      <StyledTableCell >No. of swaps Remaining</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell>{booking.activeSubscription.swapsAllowed}</StyledTableCell>
                      <StyledTableCell>{booking?.consumedSwaps?.length || 0}</StyledTableCell>
                      <StyledTableCell>{booking.activeSubscription.swapsAllowed - (booking?.consumedSwaps?.length || 0)}</StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        )
      }
      {
        booking.swapRequests && booking.swapRequests.length > 0 &&
        <Grid item xs={12} style={{ marginTop: 15 }}>
          <div className={`${styles.box}`}>
            <div className="font-medium semi-bold margin-bottom padding-bottom">SWAP REQUEST HISTORY</div>
            <div style={{ margin: "0 -10px -10px -10px" }}></div>
            <TableContainer component={Paper} style={{ maxHeight: "100%", }} className={`${booking.businessCustomer ? "row-hover" : ""}`}>
              <Table aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell >REQUESTED ON</StyledTableCell>
                    <StyledTableCell >SWAP DATE</StyledTableCell>
                    <StyledTableCell >STATUS</StyledTableCell>
                    <StyledTableCell >Old Vehicle</StyledTableCell>
                    <StyledTableCell >New Vehicle</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {
                    booking.swapRequests.map((swapRequest: ISwapRequest, index: number) => {
                      return (
                        <>
                          <StyledTableRow
                            onClick={() => {
                              setOpenSwapRequestDialog(true)
                              setSelectedRequestToUpdate(swapRequest)
                            }}
                          >
                            <StyledTableCell>
                              {d.fromISO(swapRequest.createdAt).toFormat(DateTimeFormat.DEFAULT_DATE_EXPANDED)}
                            </StyledTableCell>
                            <StyledTableCell>
                              {d.fromISO(swapRequest.dateForSwap).toFormat(DateTimeFormat.DEFAULT_DATE_EXPANDED)}
                            </StyledTableCell>
                            <StyledTableCell>
                              {swapRequest.status}
                            </StyledTableCell>
                            <StyledTableCell>
                              {`${swapRequest.vehicle?.make} ${swapRequest.vehicle?.model}(${swapRequest.vehicle?.licencePlate})`}
                            </StyledTableCell>
                            <StyledTableCell >
                              {(swapRequest.status === SwapRequestStatus.APPROVED || swapRequest.status === SwapRequestStatus.COMPLETED) ? `${swapRequest.vehicleAllocated?.make} ${swapRequest.vehicleAllocated?.model} (${swapRequest.vehicleAllocated?.licencePlate})` : ""}
                            </StyledTableCell>
                          </StyledTableRow>
                        </>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
      }
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <div className={`${styles.box}`}>
          <Grid item xs={12}>
            <DriversInformation booking={booking} />
          </Grid>
        </div>
      </Grid>
      {booking && booking.addonRequirements && booking.addonRequirements.length > 0 && <Grid item xs={12} style={{ marginTop: 10 }}>
        <div className={`${styles.box}`}>
          <div className="font-medium semi-bold margin-bottom padding-bottom">Add-ons Details</div>
          <div style={{ margin: "0 -10px -10px -10px" }}>
            <TableContainer style={{ maxHeight: "100%", }}>
              <Table stickyHeader aria-label="customized table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Add On</StyledTableCell>
                    <StyledTableCell>Description</StyledTableCell>
                    <StyledTableCell>Quantity</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {booking.addonRequirements.map(
                    (item, index) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell>
                            {item.displayName?.toUpperCase() ||
                              item.name?.toUpperCase()}
                          </StyledTableCell>
                          <StyledTableCell>{item.addon.description}</StyledTableCell>
                          <StyledTableCell>{item.quantity}</StyledTableCell>
                        </StyledTableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Grid>}
      {!website.organisation.closeGroupSharingEnabled && booking.invoices.length > 0 && <>
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <div className={`${styles.box}`}>
          <div className="font-medium semi-bold margin-bottom padding-bottom">Payment Details</div>
          <div style={{ margin: "0 -10px -10px -10px" }}>
            <TableContainer style={{ maxHeight: "100%", }} className={user?.id ? "row-hover" : ""}>
              <Table stickyHeader aria-label="customized table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Invoice Number</StyledTableCell>
                    <StyledTableCell>Invoice Type</StyledTableCell>
                    <StyledTableCell>Creation Date</StyledTableCell>
                    <StyledTableCell>Billing Period</StyledTableCell>
                    <StyledTableCell>Amount</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {booking && booking.invoices && booking.invoices.map(
                    (invoice: IInvoice, index: number) => {
                      return (
                        <StyledTableRow key={index} onClick={() => user?.id && history.push(`/account/invoices/${invoice.id}`)}>
                          <StyledTableCell >
                            <span
                              style={user?.id ? {
                                color: "rgb(24, 117, 210)",
                                textDecoration: "none",
                                cursor: "pointer",
                              } : {}}>{invoice.invoiceRef}</span>
                          </StyledTableCell>
                          <StyledTableCell>
                            {invoice.invoiceType === InvoiceType.SUNDRY ? InvoiceType.SUNDRY : "REGULAR"}
                          </StyledTableCell>
                          <StyledTableCell>
                            {getLocalizedDateFormat(country, (invoice.confirmedAt) ? invoice.confirmedAt : invoice.dateCreated, DATE_TYPE.CONDENSED)}
                          </StyledTableCell>
                          <StyledTableCell>{`${getLocalizedDateFormat(country, invoice.startDate, DATE_TYPE.CONDENSED)}-${getLocalizedDateFormat(country, invoice.endDate, DATE_TYPE.CONDENSED)}`}</StyledTableCell>
                          <StyledTableCell>{toCurrency(invoice.totalPayableAmount, currency, locale)}</StyledTableCell>
                          <StyledTableCell>{invoice.status}</StyledTableCell>
                        </StyledTableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Grid>
      </>
      }
      {!website.organisation.closeGroupSharingEnabled && <>
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <div className={`${styles.box}`}>
          <div className="font-medium semi-bold margin-bottom padding-bottom">Deposit Details</div>
          <div className="font-medium semi-bold">
            <span>
              Amount : {toCurrency(
                Number(booking.depositDetails?.amount || 0),
                currency,
                locale
              )}
            </span>
            {
              booking?.depositDetails && booking.depositDetails.amount > 0 &&
              <span className='padding-left margin-left'>Payment Status: {(booking.depositDetails?.payment?.status || "N/A")}</span>
            }
          </div>
        </div>
      </Grid>
      </>
      }
      {openSwapRequestDialog && (
        <GenerateSwapRequestDialog
          open={openSwapRequestDialog}
          onClose={() => setOpenSwapRequestDialog(false)}
          onCreateSwapRequest={onCreateSwapRequest}
          activeSubscription={booking.activeSubscription}
          swapRequest={selectedRequestToUpdate}
          cancelSwapRequest={onCancelSwapRequest}
          loadingCancelSwap={loadingCancelSwap}
          loadingUpdateBookingSwapData={loadingUpdateBookingSwapData}
          loadingCreateSwapRequest={loadingCreateSwapRequest}
          booking={booking}
        />
      )}
    </Grid>
  )
}

export default BookingSummary