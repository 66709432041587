import { useLazyQuery, useMutation } from "@apollo/client";
import { Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, Typography, } from "@material-ui/core";
import { ApolloError } from "apollo-boost";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import styles from './index.module.css';
import { IBooking, ICreditNote, PaymentGateway } from "../../reducers/bookings/types";
import { useSnackBar } from "../SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../SnackbarWrapper/SnackbarWrapper";
import { IAppState } from "../../store";
import { CREATE_PAYMENT } from "../../graphql/invoices/createPaymentMutation";
import { formatGraphQLErrorMessage, toCurrency } from "../utils";
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { CONFIRM_BOOKING } from '../../graphql/bookings/confirmBookingMutation';
import StripePayment from "./StripePayment";
import { RATE_TYPES } from '../views/utils';
import { DepositCollection, MinimumBookingAmountTypes } from "../consts";
import { CHECK_BOOKING_CONFIRMATION_ELIGIBILITY } from "../../graphql/bookings/checkBookingConfirmationEligibility";


export interface ICreditNotesToAdjust {
  creditNoteId: string;
  amount: number;
}

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  booking: IBooking;
  onConfirmation: (bookingId: string) => void;
  returnUrl: string
  adjustableCreditNotes?: ICreditNote[]
}

const PaymentDialog: React.FC<IProps> = ({ booking, open, setOpen, onConfirmation, returnUrl, adjustableCreditNotes }) => {
  const snackbar = useSnackBar();
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const [loading, setLoading] = useState<boolean>(false);
  const { currency, stripeAccountId, locale, autoChargeEnabled, convergeCredentials } = website.organisation;
  const [paymentCollection, setPaymentCollection] = useState<boolean>(false);
  const [stripePaymentIntentSecret, setStripePaymentIntentSecret] = useState<string>("");
  const [saveDetails, setSaveDetails] = useState<boolean>(false);
  const [amountToPay, setAmountToPay] = useState<number>(0);
  const [collectDeposit, setCollectDeposit] = useState<boolean>(false);
  const [confirmDirectly, setConfirmDirectly] = useState(false);
  const [captureLater, setCaptureLater] = useState(false);
  const [creditNotesApplied, setCreditNotesApplied] = useState<boolean>(false);
  const [adjustableAmount, setAdjustableAmount] = useState<number>(0);
  const [creditNotesToAdjust, setCreditNotesToAdjust] = useState<ICreditNotesToAdjust[]>([]);
  const [bookingType, setBookingType] = useState<string>('PREPAID');
  const [paymentGatewayAvailable, setPaymentGatewayAvailable] = useState<boolean>(false);

  useEffect(() => {
    if (website && booking) {
      const selectedbranch = website.branches.find(branch => branch.id === booking.branchId);
      if (selectedbranch?.depositCollection === DepositCollection.AT_BOOKING_CONFIRMATION && booking?.depositDetails?.amount ) {
        setCollectDeposit(true);
      }
      if (
        (website.paymentGateway === PaymentGateway.STRIPE && stripeAccountId) ||
        (website.paymentGateway === PaymentGateway.CONVERGE && convergeCredentials?.merchantAccountId)
      ) {
        setPaymentGatewayAvailable(true);
      }
    }
  }, [website, booking])

  useEffect(() => {
    if (booking) {
      setAmountToPay(getTotalPayableAmount())
    }
  }, [booking, collectDeposit])

  useEffect(() => {
    if (adjustableCreditNotes?.length) {
      const amount = calcAdjustableAmount(adjustableCreditNotes);
      setAdjustableAmount(amount)
    }
  }, [adjustableCreditNotes])

  useEffect(() => {
    if (autoChargeEnabled) {
      setSaveDetails(true)
    }
  }, [autoChargeEnabled])

  const handleDialogClose = () => {
    setOpen(false);
  };

  const [checkBookingConfirmationEligibility] = useLazyQuery(
    CHECK_BOOKING_CONFIRMATION_ELIGIBILITY,
    {
      fetchPolicy: "network-only",
      onError: (error: ApolloError) => {
        snackbar({
          message: formatGraphQLErrorMessage(error.message),
          variant: SnackBarVariant.ERROR
        });
        setLoading(false);
      },
      onCompleted: (data) => {
        if (data.bookingConfirmationEligibility) {
          if (booking) {
            if (confirmDirectly) {
              if (creditNotesToAdjust.length) {
                createPayment({ // signifies the case of pay on collection with some credit notes to adjust
                  variables: {
                    payment: {
                      bookingId: booking.id,
                      currency,
                      successUrl: `${window.location.protocol}//${window.location.host}/account/bookings/${booking.id}`,
                      cancelUrl: `${window.location.protocol}//${window.location.host}/account/bookings/${booking.id}`,
                    },
                    saveDetails,
                    collectDeposit: false, // because credit notes can't be adjusted against deposit
                    creditNotesToAdjust
                  }
                })
              } else {
                confirmBookingMutation({
                  variables: {
                    bookingId: booking.id,
                    branchId: booking.branchId,
                    bookingType
                  }
                })
              }
            } else {
              if (amountToPay) {
                createPayment({
                  variables: {
                    payment: {
                      bookingId: booking.id,
                      currency,
                      amount: amountToPay,
                      captureLater,
                      successUrl: `${window.location.protocol}//${window.location.host}/account/bookings/${booking.id}`,
                      cancelUrl: `${window.location.protocol}//${window.location.host}/account/bookings/${booking.id}`,
                    },
                    saveDetails,
                    collectDeposit,
                    creditNotesToAdjust
                  }
                });
              } else {
                createPayment({
                  variables: {
                    payment: {
                      bookingId: booking.id,
                      currency,
                      successUrl: `${window.location.protocol}//${window.location.host}/account/bookings/${booking.id}`,
                      cancelUrl: `${window.location.protocol}//${window.location.host}/account/bookings/${booking.id}`,
                    },
                    saveDetails,
                    collectDeposit: false, // because credit notes can't be adjusted against deposit
                    creditNotesToAdjust
                  }
                });
              }
            }
          }
        }
      }
    }
  );

  const [createPayment, { loading: sessionLoading, data: createPaymentData }] = useMutation(CREATE_PAYMENT, {
    onError: (error: ApolloError) => {
      // alert(error.message);
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: async (data) => {
      if (data?.consumerCreatePayment.success && booking) {
        if (data?.consumerCreatePayment?.payment?.stripePaymentIntentSecret) {
          const stripePromise: any = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "", {
            stripeAccount: stripeAccountId
          });
          // Get Stripe.js instance
          const stripe = await stripePromise;
          // When the customer clicks on the button, redirect them to Checkout.
          if (stripe) {
            setLoading(false)
            setStripePaymentIntentSecret(data.consumerCreatePayment.payment.stripePaymentIntentSecret)
            setPaymentCollection(true);
          }
        } else if (data.consumerCreatePayment.payment?.convergePaymentSessionUrl) {
          window.location = data.consumerCreatePayment.payment.convergePaymentSessionUrl;
        } else {
          onConfirmation(booking.id)
          handleDialogClose()
        }
      }
    }
  });

  const [confirmBookingMutation] = useMutation(CONFIRM_BOOKING, {
    onCompleted: () => {
      onConfirmation(booking.id);
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      }),
  });

  const handlePreAuthPayment = async () => {
    setCaptureLater(true)
    setConfirmDirectly(false)
    setLoading(true);
    setAmountToPay(getTotalPayableAmount())
    if (booking) {
      checkBookingConfirmationEligibility({
        variables: {
          bookingId: booking.id
        }
      })
    }
  }

  const calcAdjustableAmount = (creditNotes: ICreditNote[]) => {
    let adjustableAmount: number = 0;
    if (creditNotes.length) {
      creditNotes.forEach((note: ICreditNote) => {
        adjustableAmount += note.availableAmount
      })
    };
    return adjustableAmount;
  }

  const handlePaymentClick = async (amount?: number) => {
    setCaptureLater(false)
    setConfirmDirectly(false)
    if (amount) {
      setAmountToPay(amount)
    } else {
      setAmountToPay(getTotalPayableAmount())
    }
    setLoading(true);
    if (booking) {
      checkBookingConfirmationEligibility({
        variables: {
          bookingId: booking.id
        }
      })
    }
  };

  const handleConfirmBooking = async () => {
    setLoading(true);
    setCaptureLater(false)
    setConfirmDirectly(true);
    setAmountToPay(getTotalPayableAmount())
    if (booking) {
      checkBookingConfirmationEligibility({
        variables: {
          bookingId: booking.id
        }
      })
    }
  };

  const handlePayLater = async () => {
    setLoading(true);
    setCaptureLater(false)
    setConfirmDirectly(true);
    setBookingType('POSTPAID')
    setAmountToPay(getTotalPayableAmount())
    if (booking) {
      checkBookingConfirmationEligibility({
        variables: {
          bookingId: booking.id,
          bookingType: 'POSTPAID'
        }
      })
    }
  }

  useEffect(() => {
    if (creditNotesApplied && adjustableCreditNotes?.length && booking) {
      const adjustableCreditNotesArr = [...adjustableCreditNotes].sort((a,b) => (b.availableAmount - a.availableAmount) > 0 ? 1 : -1)
      const arr: ICreditNotesToAdjust[] = [];
      let payableAmount = booking.paymentDetails.totalPayableAmount;
      let amountPaid = 0;
      let amountRemaining = payableAmount
      for (let i = 0; i < adjustableCreditNotesArr.length; i++) {
        const creditNote: ICreditNote = adjustableCreditNotesArr[i];
        if (amountPaid >= payableAmount) {
          break;
        }
        if (creditNote.availableAmount >= amountRemaining) {
          amountPaid += amountRemaining
          arr.push({
            creditNoteId: creditNote.id,
            amount: amountRemaining
          })
          amountRemaining -= amountRemaining
        } else {
          amountPaid += creditNote.availableAmount
          arr.push({
            creditNoteId: creditNote.id,
            amount: creditNote.availableAmount
          })
          amountRemaining -= creditNote.availableAmount
        }
      }
      setCreditNotesToAdjust(arr);
    }
  }, [creditNotesApplied])

  const handleCheckboxChange = () => {
    if (creditNotesApplied) { // signifies unchecking of checkbox as previous state was true.
      setCreditNotesToAdjust([]) // if checkbox unchecked then creditNotesToAdjust should be empty
    }
    setCreditNotesApplied(!creditNotesApplied)
  }

  if (!booking) {
    return (
      <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  const getMinimumPayableAmount = () => {
    let amount: number = -1;
    const selectedbranch = website.branches.find(branch => branch.id === booking.branchId);
    if (selectedbranch?.minBookingAmountOptIn && selectedbranch.minimumBookingAmount.type) {
      const chargeType = selectedbranch.minimumBookingAmount.type;
      if (chargeType === MinimumBookingAmountTypes.FIXED) {
        amount = selectedbranch.minimumBookingAmount.value
      } else if (chargeType === MinimumBookingAmountTypes.VARIABLE) {
        amount = Math.round((selectedbranch.minimumBookingAmount.value * booking.paymentDetails.totalPayableAmount) / 100)
      }
      if (paymentGatewayAvailable && collectDeposit) {
        if (booking.depositDetails?.amount && booking.depositDetails.amount > amount) {
          amount = booking.depositDetails.amount
        }
        if (amount > (booking.paymentDetails.totalPayableAmount + (booking.depositDetails?.amount || 0))) {
          amount = -1
        }
      } else {
        if (amount > booking.paymentDetails.totalPayableAmount) {
          amount = -1
        }
      }
    }
    return amount
  }

  const getTotalPayableAmount = () => {
    let amount = booking.paymentDetails.totalPayableAmount;
    if (collectDeposit && booking.depositDetails?.amount) {
      amount += booking.depositDetails.amount
    }
    if (creditNotesApplied && creditNotesToAdjust.length) {
      creditNotesToAdjust.forEach((item) => {
        amount -= item.amount
      })
    }
    if (amount > 0) {
      return amount
    }
    return 0;
  }

  return (
    <Dialog
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="form-dialog-title"
    >
      <div className={`${styles.paymentDialog} ${styles.mobile}`}>
        <div className={styles.closeDialog}>
          <Typography variant="h2" className="bold">Proceed to pay</Typography>
          <IconButton onClick={() => handleDialogClose()} aria-label="delete">
            <CloseIcon />
          </IconButton>
        </div>
        {!paymentCollection && <>
          <div style={{ marginBottom: 10 }} className="flex padding-left space-between">
            <Grid container xs={12}>
              <Grid container xs={5}>
                <Typography variant="h4" style={{ fontWeight: 'initial', display: "inline" }}>Rental Charges:</Typography>
                <Typography variant="h3" style={{marginLeft: "0.5rem", marginTop: "-0.2rem", display: "inline" }} className="bold text-accent">{toCurrency(booking.paymentDetails.totalPayableAmount, currency, locale)}</Typography>
              </Grid>
              <Grid container xs={7}>
                {adjustableAmount > 0 && <Grid xs={11}>
                  <Grid container xs={12} style={{marginTop: "-0.9rem", marginLeft: "2rem"}}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={creditNotesApplied}
                            onChange={handleCheckboxChange}
                            value={creditNotesApplied}
                            color="secondary"
                            disabled={loading}
                          />
                        }
                        label={
                          <Typography variant="body1">
                            {`Use Credit Note Balance: ${toCurrency(adjustableAmount, currency, locale)}`}
                          </Typography>}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>}
              </Grid>
            </Grid>
          </div>
          { collectDeposit && booking.depositDetails?.amount &&
            <div style={{ marginBottom: 15 }} className="flex padding-left space-between">
              <div>
                <Typography variant="h4" style={{ fontWeight: 'initial', display: "inline" }}>Deposit:</Typography>
                <Typography variant="h3" style={{marginLeft: "1rem", display: "inline" }} className="bold text-accent">{toCurrency(booking.depositDetails?.amount, currency, locale)}</Typography>
              </div>
            </div>
          }
          <hr></hr>
        </>}
        <div style={{ marginBottom: 35 }} className="flex padding-left space-between">
          <div>
            <Typography variant="h4" style={{ fontWeight: 'initial', display: "inline"}}>Total payable amount:</Typography>
            <Typography variant="h2" style={{marginLeft: "1rem", display: "inline" }} className="bold text-accent">{toCurrency(amountToPay || getTotalPayableAmount(), currency, locale)}</Typography>
          </div>
        </div>
        {paymentCollection ?
          <StripePayment stripePaymentIntentSecret={stripePaymentIntentSecret} bookingId={booking.id} returnUrl={returnUrl} /> :
          loading ? <CircularProgress /> : getTotalPayableAmount() > 0 ? <>
            {getMinimumPayableAmount() > 0 && !creditNotesApplied ? <div onClick={() => handlePaymentClick(getMinimumPayableAmount())} className={`${styles.payAmoutButton} bold padding-top`}>
              <div>
                <Typography variant="h4" className="bold flex space-between">
                  <span style={{ paddingRight: 40 }}>Pay minimum amount</span>
                  <span className="text-accent">{toCurrency(getMinimumPayableAmount(), currency, locale)}</span>
                </Typography>
                <Typography variant="body1" className="text-grey flex space-between cross-center">
                  <span style={{ paddingRight: 50 }}>Due amount to be paid at the time of pickup</span>
                  <ArrowForwardIcon style={{ marginRight: -4, fontSize: 16 }} />
                </Typography>
              </div>
              <span></span>
            </div> : null}
            {paymentGatewayAvailable && getMinimumPayableAmount() === 0 ? <div onClick={() => handlePreAuthPayment()} className={`${styles.payAmoutButton} bold padding-top`}>
              <div>
                <Typography variant="h4" className="bold flex space-between">
                  <span style={{ paddingRight: 40 }}>Pay on collection</span>
                  <span className="text-accent">{toCurrency(getTotalPayableAmount(), currency, locale)}</span>
                </Typography>
                <Typography variant="body1" className="text-grey flex space-between cross-center">
                  <span style={{ paddingRight: 50 }}>Card will be authorised now but amount will be deducted when booking starts</span>
                  <ArrowForwardIcon style={{ marginRight: -4, fontSize: 16 }} />
                </Typography>
              </div>
              <span></span>
            </div> : null}
            {!paymentGatewayAvailable && getMinimumPayableAmount() === 0 ? <div onClick={() => handleConfirmBooking()} className={`${styles.payAmoutButton} bold padding-top`}>
              <div>
                <Typography variant="h4" className="bold flex space-between">
                  <span style={{ paddingRight: 40 }}>Pay on collection</span>
                  <span className="text-accent">{toCurrency(getTotalPayableAmount(), currency, locale)}</span>
                </Typography>
                <Typography variant="body1" className="text-grey flex space-between cross-center">
                  <span style={{ paddingRight: 50 }}>Confirm booking now and pay at the time of pickup</span>
                  <ArrowForwardIcon style={{ marginRight: -4, fontSize: 16 }} />
                </Typography>
              </div>
              <span></span>
            </div> : null}
            {paymentGatewayAvailable && <div onClick={() => handlePaymentClick(getTotalPayableAmount())} className={`${styles.payAmoutButton} bold ${styles.full}`}>
              <div>
                <Typography variant="h4" className="bold flex space-between">
                  <span style={{ paddingRight: 40 }}>Pay full amount now</span>
                  <span className="text-accent">{toCurrency(getTotalPayableAmount(), currency, locale)}</span>
                </Typography>
                <Typography variant="body1" className="text-grey flex space-between cross-center">
                  <span style={{ paddingRight: 50 }}>Pay full amount right away</span>
                  <ArrowForwardIcon fontSize="small" style={{ marginRight: -4, fontSize: 16 }} />
                </Typography>
                {
                  autoChargeEnabled ? 
                  <Grid item xs={12}>
                    <Typography>
                      Future charges related to the ongoing booking will be charged to this payment method
                    </Typography> 
                  </Grid> :
                  [RATE_TYPES.MONTHLY, RATE_TYPES.WEEKLY].includes(booking.rateTypeName) &&
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveDetails}
                          onChange={(e: any) => {
                            setSaveDetails(e.target.checked)
                          }}
                          onClick={(e: any) => {
                            e.stopPropagation()
                          }}
                          value={saveDetails}
                          color="primary"
                          name={"agreed"}
                          disabled={sessionLoading}
                        />
                      }
                      label={
                        <Typography variant="body1">
                          Save this card to make payment for future invoices on this booking.
                        </Typography>
                      }
                    />
                  </FormGroup>
                }
              </div>
            </div>}
            {(booking.businessCustomer && booking.businessCustomer.creditLimitEnabled) ? <div onClick={() => handlePayLater()} className={`${styles.payAmoutButton} bold padding-top`}>
          <div>
              <Typography variant="h4" className="bold flex space-between">
                  <span style={{ paddingRight: 40 }}>Confirm Booking, Pay Later</span>
                  <span className="text-accent">{toCurrency(getTotalPayableAmount(), currency, locale)}</span>
              </Typography>
              <Typography variant="body1" className="text-grey flex space-between cross-center">
                  <span style={{ paddingRight: 50 }}>{`Credit limit assigned for this business will be used for this payment`}</span>
                    <ArrowForwardIcon style={{ marginRight: -4, fontSize: 16 }} />
              </Typography>
            </div>
              <span></span>
          </div> : null}
          </> : 
          <>
            <div onClick={() => handlePaymentClick(getTotalPayableAmount())} className={`${styles.payAmoutButton} bold padding-top`}>
              <div>
                <Typography variant="h4" className="bold flex space-between">
                  <span style={{ paddingRight: 40 }}>Confirm Booking</span>
                </Typography>
                <Typography variant="body1" className="text-grey flex space-between cross-center">
                  <span style={{ paddingRight: 50 }}>Credits will be used against this booking</span>
                  <ArrowForwardIcon style={{ marginRight: -4, fontSize: 16 }} />
                </Typography>
              </div>
              <span></span>
            </div> 
          </>
        }
      </div>
    </Dialog>
  );
};

export default PaymentDialog;
