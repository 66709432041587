import { gql } from "apollo-boost";

export const GET_AVAILABLE_SUBSCRIPTION_VEHICLES = gql`
  query consumerAvailableSubscriptionVehicles(
    $input: ConsumerAvailableSubscriptionVehiclesInput!
  ) {
    consumerAvailableSubscriptionVehicles(input: $input) {
      id
      bodyType
      make
      model
      year
      licencePlate
      numberOfDoors
      numberOfSeats
      transmission
      fuelType
      imageUrl
      subscriptions {
        deposit
        subscription {
          isActive
          id
          name
          rateTypeName
          addons {
            id
            name
            displayName
          }
          insurancePolicy {
            id
            name
          }
        }
        pricings {
          duration
          enrollmentAmount
          mileage
          subscriptionAmount
          pricePerExtraMile
          unlimitedMileage
          swapsAllowed
        }
      }
    }
  }
`;
