import { useMutation } from "@apollo/client";
import { CircularProgress, Typography, } from "@material-ui/core";
import { ApolloError } from "apollo-boost";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import CloseIcon from '@material-ui/icons/Close';
import styles from './index.module.css';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { IInvoice, IPaymentInput, PaymentGateway, PaymentMode } from '../../../../reducers/bookings/types';
import { useSnackBar } from '../../../SnackBarContext/SnackBarContext';
import { formatGraphQLErrorMessage, toCurrency } from '../../../utils';
import { CREATE_INVOICE_PAYMENT } from '../../../../graphql/invoices/createPaymentMutation';
import { IAppState } from '../../../../store';
import { SnackBarVariant } from '../../../SnackbarWrapper/SnackbarWrapper';
import StripePayment from '../../../BookingConfirmation/StripePayment';
import { InvoiceType } from "../../NewBooking/Invoices/utils";

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  bookingId: string;
  invoice: IInvoice;
  onConfirmation: (invoice: IInvoice) => void;
  returnUrl: string
}

const InvoicePaymentDialog: React.FC<IProps> = ({ invoice, open, setOpen, onConfirmation, returnUrl, bookingId }) => {
  const snackbar = useSnackBar();
  const [loading, setLoading] = useState<boolean>(false);
  const website = useSelector((state: IAppState) => state.consumerWebsiteReducer.consumerWebsite);
  const { currency, stripeAccountId, locale } = website.organisation;
  const [stripePaymentIntentSecret, setStripePaymentIntentSecret] = useState<string>("");

  useEffect(() => {
    if (invoice) {
      const payment: IPaymentInput = {
        amount: invoice.dueAmount,
        booking: bookingId || "",
        currency,
        description: PaymentMode.CARD,
        expireBy: "",
        invoice: invoice.id,
        paymentGateway: website.paymentGateway || PaymentGateway.STRIPE,
        paymentMode: PaymentMode.CARD,
        paymentType: "INWARD",
        successUrl: `${window.location.protocol}//${window.location.host}/account/invoices/${invoice.id}`,
        cancelUrl: `${window.location.protocol}//${window.location.host}/account/invoices/${invoice.id}`
      };
      if (invoice.invoiceType === InvoiceType.CONSOLIDATED && invoice.businessCustomer) {
        payment.businessCustomer = invoice.businessCustomer.id;
        payment.isConsolidated = true;
      }
      createPayment({
        variables: {
          payment: {
            ...payment,
            booking: bookingId || ""
          }
        }
      });
    }
  }, [invoice])

  const handleDialogClose = () => {
    setOpen(false);
  };


  const stripePromise: any = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "", {
    stripeAccount: stripeAccountId
  });

  const [createPayment, { loading: sessionLoading, data: createPaymentData }] = useMutation(CREATE_INVOICE_PAYMENT, {
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    },
    onCompleted: async (data) => {
      if (data?.createPayment?.payment?.stripePaymentIntentSecret) {
        // Get Stripe.js instance
        const stripe = await stripePromise;
        // When the customer clicks on the button, redirect them to Checkout.
        if (stripe) {
          setLoading(false)
          setStripePaymentIntentSecret(data.createPayment.payment.stripePaymentIntentSecret)
        }
      } else if (data.createPayment.payment?.convergePaymentSessionUrl) {
        window.location = data.createPayment.payment.convergePaymentSessionUrl;
      }
    }
  });

  return (
    <Dialog
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="form-dialog-title"
    >
      <div className={`${styles.paymentDialog} ${styles.mobile}`}>
        <div className={styles.closeDialog}>
          <Typography variant="h2" className="bold">Proceed to pay</Typography>
          <IconButton onClick={() => handleDialogClose()} aria-label="delete">
            <CloseIcon />
          </IconButton>
        </div>
        <div style={{ marginBottom: 35 }} className="flex padding-left space-between">
          <div>
            <Typography variant="h4" style={{ fontWeight: 'initial' }}>Total payable amount</Typography>
            <Typography variant="h2" className="bold text-accent">{toCurrency(invoice.dueAmount, currency, locale)}</Typography>
          </div>
        </div>
        {loading ? <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </div> :
          <StripePayment stripePaymentIntentSecret={stripePaymentIntentSecret} bookingId={bookingId} returnUrl={returnUrl} />}
      </div>
    </Dialog>
  );
};

export default InvoicePaymentDialog;
