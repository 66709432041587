import { string } from "yup/lib/locale";
import { VehicleBodyTypes } from "../../components/views/CustomerPortal/Vehicle/VehicleDamage/FrogDiagrams";
import { IAddonType } from "../../components/views/NewBooking/SelectAddOns/types";
import { RATE_TYPES } from "../../components/views/utils";
import { IBusinessCustomer, IPhoneNumber, IUser } from "../auth/types";
import { IBranch } from "../website/types";
import { IMapRouteData } from "../../components/views/CustomerPortal/VehicleTracking/TelematicsView";

export interface IBookingVehiclePriceGroup extends IVehiclePriceGroup {
  id: string;
}

export interface IBookingState {
  booking: IBooking;
  invoiceId?: string;
  vehicleSelectionView: VehicleSelectionView;
  createBookingInput?: IBookingCreateInput;
}

export interface CheckInfo {
  vehicleState: any; //FIX: Typing
  customerInfo: VehicleCheckCustomerInfo;
  paymentInfo: IVehicleCheckoutPaymentInfo;
}
export interface VehicleCheckCustomerInfo {
  tncCheck: boolean;
  name: string;
  signature: string;
}

export interface IVehicleCheckoutPaymentInfo {
  paymentMethod: string;
  amount: number;
}
export interface IVehiclePriceGroup {
  id?: string;
  name: string;
  fleetType?: string;
  criteria: IVehicleGroupCriteria[];
  vehicles: IVehicle[];
  deposit: number;
  basePrices: IRate[];
  isActivated: boolean;
  imageUrl: string;
  images: VehicleImages[]
  sharedVehicles?: IVehicle[]
}


export interface VehicleImages {
  idx:number
  url:string
  default:boolean
}

export interface IRate {
  rateTypeName: string;
  rateTypeDuration: number;
  rate: number;
  longTerm: boolean;
  mileageLimit: number;
  pricePerExtraMile: number;
  applicableAmount?: number;
  priceRuleAmount?: number;
  priceRuleName?: string;
  priceRule?: string;
  priceRuleOperator?: string;
  priceRuleValueType?: ValueType;
  priceRuleTriggerType?: string;
  checked?: boolean;
  unlimitedMileage: boolean;
}

export interface IVehicleGroupCriteria {
  type: string;
  values: string[];
}

export interface IServiceLocation {
  id: string;
  address: IAddress;
  name: string;
  branch?: string;
}

export interface IAddress {
  street: string;
  city: string;
  fullAddress: string;
  state: string;
  country: string;
  zipcode: string;
  geometry?: IGeometry;
}

export interface IGeometry {
  type: string;
  latitude: number;
  longitude: number;
}

export interface ICustomer {
  id: string;
  status: string;
  firstName: string;
  lastName: string;
  phoneNumber: IPhoneNumberInput;
  email: string;
  address: string;
  dateOfBirth: string;
  location: ILocation;
  // notes?: INote[];
  verified: boolean;
  verificationMethod: string;
  deleted: boolean;
  secondaryPhoneNumber: IPhoneNumberInput;
  secondaryEmail: string;
  documents: ICustomerDocument[];
  gender: string;
  license: ILicenseDetails;
  customerPCONumber?: string;
}

export interface ILicenseDetails {
  authority: string;
  licenseNumber: string;
  dateOfExpiry: string;
  endorsement: string;
}

export interface ICustomerDocument {
  id: string;
  documentKey: string;
  title: string;
  description?: string;
  type?: string;
  createdAt?: string;
}

export interface ILocation {
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  zipcode?: string;
  fullAddress?: string;
}

export enum VehicleSelectionView {
  REGISTRATION,
  CATEGORY,
}

export type InvoicesPayloadType = IInvoice | IInvoiceId;

export interface IInvoice {
  id: string;
  latestPayment?: string;
  latestRefund?: string;
  customer: ICustomerDetails;
  businessCustomer?: IBusinessCustomer;
  customerType: string;
  dateCreated: string;
  dateUpdated: string;
  createdBy: string;
  updatedBy: string;
  discount: IDiscount;
  invoiceRef: string;
  status: InvoiceStatus;
  locked: boolean;
  invoiceType: string;
  refunds: string[];
  startDate: string;
  endDate: string;
  tax: ITax[];
  totalPayableAmount: number;
  anyPaidAmount: number;
  confirmedPaidAmount: number;
  anyRefundAmount: number;
  confirmedRefundAmount: number;
  otherRefunds: number;
  depositRefund: number;
  balanceDueFromPreviousInvoice: number;
  damageAmount: number;
  finesAmount: number;
  valetAmount: number;
  otherCharges: IOtherCharge[];
  invoiceItems: IInvoiceItem[];
  lastSent?: string;
  booking: IBooking;
  payments: IPayment[];
  taxAmount: number;
  rentalAmount: number;
  insuranceAmount: number;
  discountAmount: number;
  addonsAmount: number;
  rateTypeName: string;
  rateTypeDuration: number;
  sageAccountingInvoiceId: string;
  sageAccountingError: string;
  invoiceUrl?: string;
  documents?: IInvoiceDocument[];
  dueDate: string;
  dueAmount: number;
  adjustedDeposit?: IAdjustedDeposit;
  adjustedCreditNotes?: IAdjustedCreditNote[],
  invoices?: IInvoiceSummary[],
  consolidatedBy?: IInvoiceSummary,
  confirmedAt?: string
}

export interface IInvoiceSummary {
  id: string;
  invoiceRef: string;
}

export interface IAdjustedCreditNote {
  amount: number;
  creditNote: ICreditNote;
  adjustedAt: string;
}

export enum InvoiceStatus {
  PAID = "PAID",
  UNPAID = "UNPAID",
  VOID = "VOID",
  PARTIALLY_PAID = "PARTIALLY PAID",
  DRAFT = "DRAFT",
}

export interface IAdjustedDeposit {
  amount: number;
  adjustedAt: string;
}

export interface IOtherCharge {
  amount: number;
  name: string;
}

export interface IInvoiceItem {
  type: IInvoiceItemType;
  description: string;
  unitPrice: number;
  totalAmount: number;
  quantity: number;
  duration?: number;
  taxRate?: number;
  taxAmount?: number;
  isProrated?: boolean;
  proratedBillingDays?: number;
  taxDescription?: string[];
  rateTypeName?: string;
}

export enum IInvoiceItemType {
  "RENTAL_AMOUNT" = "RENTAL_AMOUNT",
  "DEPOSIT" = "DEPOSIT",
  "INSURANCE" = "INSURANCE",
  "OTHER_CHARGE" = "OTHER_CHARGE",
  "ADDON" = "ADDON",
  "LOCATION_SURCHARGE" = "LOCATION_SURCHARGE",
}

export interface IInvoiceDocument {
  title: string;
  documentUrl: string;
  documentName: string;
  expiryDate: string;
  documentType: string;
  reminderBefore?: number;
}

export enum PaymentGateway {
  STRIPE = "STRIPE",
  CONVERGE = "CONVERGE"
}

export interface IPayment {
  id: string;
  billingAddress?: IBillingAddress;
  dateCreated: string;
  lastUpdated: string;
  createdBy: string;
  updatedBy: string;
  note: string;
  paymentMode: string;
  paymentType: string;
  emailNotify: boolean;
  expireBy: string;
  amount: number;
  currency: string;
  description: string;
  smsNotify: boolean;
  invoice?: IInvoiceId;
  customer?: IInvoiceCustomerId;
  status: PaymentStatus;
  stripeCheckoutSessionId?: string;
  paymentGateway?: string;
}

export interface IInvoiceId {
  id: string;
}

export interface IInvoiceCustomerId {
  id: string;
}

export enum PaymentStatus {
  PAID = "PAID",
  NEW = "NEW",
  FAILED = "FAILED",
  ABORT = "ABORT",
  EXPIRED = "EXPIRED",
  VOIDED = "VOIDED",
}

export enum PaymentMode {
  CARD = "CARD",
  CASH = "CASH",
  PAY_BY_LINK = "PAY_BY_LINK",
  PAY_BY_COLLECTION = "PAY ON COLLECTION",
}

export interface IPaymentForm {
  amount: number;
  email?: string;
  transactionId?: string;
  paymentMode: PaymentMode;
  note: string;
}

export interface IPaymentInput {
  paymentMode: PaymentMode;
  paymentType: string;
  billingAddress?: IBillingAddress;
  emailNotify?: boolean;
  expireBy: string;
  invoice?: string;
  booking: string;
  amount: number;
  currency: string;
  description: string;
  note?: string;
  smsNotify?: boolean;
  successUrl?: string;
  cancelUrl?: string;
  businessCustomer?: string;
  isConsolidated?: boolean;
  paymentGateway?: PaymentGateway;
}

export interface IBillingAddress {
  address1: string;
  address2?: string;
  city: string;
  country: string;
  firstName: string;
  lastName: string;
  postcode?: string;
}

export interface IBooking {
  id: string;
  bookingId?: string;
  dateReturned?: string;
  dateStarted?: string;
  firstInvoice?: IInvoice;
  currentInvoice?: IInvoice;
  finalInvoice?: IInvoice;
  pickupCheck?: any;
  dropoffCheck?: any;
  vehicle?: any;
  referenceNumber: string;
  dateCreated: string;
  dateUpdated: string;
  createdBy: string;
  pickupServiceLocation: IServiceLocation;
  pickupOtherLocation?: IAddressLocation;
  pickupDateTime: string;
  dropoffServiceLocation: IServiceLocation;
  dropoffOtherLocation?: IAddressLocation;
  dropoffDateTime?: string;
  longTermBooking: boolean;
  flightNumber: string;
  insuranceRate: number;
  excess: number;
  insurancePolicy?: IInsurancePolicyBooking;
  customerType: string;
  discount: IDiscount;
  tax?: ITax[];
  rateTypeName: RATE_TYPES;
  rateTypeDuration: number;
  billingCycleName?: string;
  billingCycleDuration?: number;
  status: BookingStatus;
  organisationId: string;
  vehicleGroups: IBookingVehicleGroup[];
  addonRequirements: IBookingAddonRequirement[];
  customer?: ICustomerDetails;
  invoices: IInvoice[];
  businessCustomer?: IBookingBusinessCustomer;
  authorizedSignatory?: string;
  approvedDrivers?: any;
  depositDetails?: IDepositDetails;
  paymentDetails: IPaymentDetails;
  currentBookingSchedules: IBookingSchedule[]; //FIX: typing
  previousBookingSchedules: IBookingSchedule[]; //FIX: typing
  quoteUrl?: string;
  branchId?: string;
  source?: BOOKING_SOURCE;
  locationSurcharges: ILocationSurchargeRules[];
  poNumber?: string;
  rentalAgreementUrl?: string;
  pcoNumber?: boolean;
  preBookingQuestions: IBookingQuestionInput[];
  deliveryQuestions: IBookingQuestionInput[];
  isRecurringBilling?: boolean;
  billingFrequency?: number;
  smartCarVehicle?: boolean;
  taxExempted: boolean;
  zeroCostBooking?: boolean;
  zeroCostBookingNote?: string;
  branch?: Partial<IBranch>;
  isSubscription: boolean;
  activeSubscription?: any;
  swapRequests?: ISwapRequest[];
  consumedSwaps?: IVehicleSwapInfo[];
  isCarShare?: boolean;
  oneWayRentalFee?: IOneWayRentalFee
}

export interface IBookingBusinessCustomer {
  id: string;
  businessName: string;
  registrationNumber: string;
  VAT: string;
  registeredAddress: ILocation;
  tradingAddressSame: boolean;
  tradingAddress: ILocation;
  approvedDrivers: IBusinessCustomerCommonInputWithId[];
  authorizedSignatories: IBusinessCustomerCommonInputWithId[];
  billing: IBusinessCustomerBillingInput;
  contact: IBusinessCustomerCommonInput;
  documents: IBusinessCustomerDocumentInput[];
  overrideNetPaymentTerms?: boolean;
  netPaymentTerms?: number;
  dailyBillingCycle?: number;
  weeklyBillingCycle?: number;
  monthlyBillingCycle?: number;
  creditLimitEnabled?: boolean;
  creditLimit?: number;
}

export interface IBusinessCustomerCommonInputWithId {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: IPhoneNumber;
  email: string;
  license: ILicenseDetails;
}

export interface IBusinessCustomerBillingInput {
  phoneNumber: IPhoneNumber;
  email: string;
  accountNumber?: string;
}

export interface IBusinessCustomerCommonInput {
  id?: string;
  status?: string;
  firstName: string;
  lastName: string;
  phoneNumber: IPhoneNumber;
  email: string;
}

export interface IBusinessCustomerDocumentInput {
  id?: any;
  name: string;
  title: string;
  url: string;
  expiryDate: string | undefined;
  documentType: string;
  reminderBefore?: number;
  createdAt?: string;
}

export interface IVehicleSwapInfo {
  previousVehicle: IVehicle;
  newVehicle: IVehicle;
  swappedOn: string;
}

export interface ISwapRequest {
  id?: string;
  isActive: Boolean
  status: string;
  dateForSwap: string;
  createdAt: string;
  events: ISwapRequestEvent[];
  vehicleAllocated?: IVehicle;
  vehicle?: IVehicle;
}

export interface ISwapRequestEvent {
  eventTime: string;
  eventBy: string;
  userType: string;
  notes: string;
}

export interface ISwapRequestCreateInput {
  dateForSwap: string;
  notes: string;
  bookingId: string;
  id?: string;
  vehicleAllocated: string;
}
export interface IBookingSchedule {
  id: string;
  startDate: string;
  booking: Partial<IBooking>;
  endDate: string;
  vehicle: IVehicle;
  status: number;
  vehicleStatus: string;
  dropoffCheck?: IVehicleCheckInfo;
  pickupCheck?: IVehicleCheckInfo;
  activeVehicleDamages?: [INewVehicleDamage];
  driver?: IAssignedDriver;
}

export interface IAssignedDriver {
  id: string;
  note?: string;
}

export interface IVehicleCheckInfo {
  vehicleState: IVehicleState;
  customerInfo: VehicleCheckCustomerInfo;
  vehicleStatePdfUrl: string;
}

export interface IBookingCreateInput {
  id?: string;
  pickupServiceLocation: string;
  pickupOtherLocation?: IAddressLocation;
  pickupDateTime: string;
  dropoffServiceLocation: string;
  dropoffOtherLocation?: IAddressLocation;
  dropoffDateTime?: string;
  longTermBooking: boolean;
  flightNumber: string;
  vehicleGroups: IBookingVehicleGroup[];
  insuranceRate: number;
  excess: number;
  insurancePolicy?: string;
  insuranceName?: string;
  addonRequirements: IAddonRequirementInput[];
  customer?: any; //TODO correct typing
  approvedDrivers: any; //TODO correct typing
  rateTypeName: string;
  rateTypeDuration: number;
  billingCycleName?: string;
  billingCycleDuration?: number;
  businessCustomer?: string;
  authorizedSignatory?: string;
  branchId?: string;
  preBookingQuestions?: IBookingQuestionInput[];
  deliveryQuestions?: IBookingQuestionInput[];
  customerNote?: string;
  smartCarVehicle?: boolean;
  source?: BOOKING_SOURCE;
  locationSurcharges: ILocationTaxSurcharge[];
  createB2B2CUser?: boolean;
  isRecurringBilling?: boolean;
  billingFrequency?: number;
  customerType: string;
  pcoNumber?: boolean;
  status?: string;
  poNumber?: string;
  bookingId?: string;
  isSubscription?: boolean;
  // activeSubscription?: IBookingSubscriptionCreateInput;
  activeSubscription?: any;
  activeSubscriptionExpanded?: IBookingSubscription;
  isCarShare?: boolean;
  isCoi?: boolean;
  coiDetails?: ICoiDetails
  defaultBillingCycle?: boolean
  costCenter?: string;
  projectId?: string;
}

export interface IOneWayRentalFee {
  amount: number;
  taxable: boolean;
  tax: ITax
}

export interface ITax {
  title: string;
  type: string;
  value: number;
}

export interface ICoiDetails {
  policyName: string;
  policyNumber: string;
  provider: string;
  expiryDate: string;
  documentUrl: string;
}

export interface ILocationSurchargeInput {
  id?: string;
  title: string;
  rate: string;
  type: string;
  value: number;
  taxable?: boolean;
  tax: ILocationSurchargeTaxInput;
}

export interface ILocationTaxSurcharge {
  id: string;
  name: string;
  value: number;
  rate: string;
  type: string;
  tax: ILocationSurchargeTaxInput;
}

export interface ILocationSurchargeTaxInput {
  title: string;
  value: number;
}
export interface ISimpleCustomerValues {
  firstName: string;
  lastName: string;
  phoneNumber: {
    phone: string;
    country: string;
  };
  email: string;
  location:IAddress
}
export interface ICustomerInfoInput extends ISimpleCustomerValues {
  agreeToTerms: boolean;
  preBookingQuestions: IBookingQuestionInput[];
  customerNote: string;
}
export interface IBookingQuestionInput {
  question: string;
  answer?: boolean | string;
  answerType: ANSWER_TYPES;
}
export enum ANSWER_TYPES {
  YES_NO = "YES_NO",
  DROP_DOWN = "DROP_DOWN",
  TEXT = "TEXT",
  CHECKBOX = "CHECKBOX",
}
export interface IBookingNotesInput {
  message: string;
  response: string;
}

export interface IDepositDetails {
  amount: number;
  payment: IPayment;
  adjustedAgainst: IInvoice;
  refund: IRefund;
  amountAdjusted: number;
}

export interface IPaymentDetails {
  startDate: string;
  endDate: string;
  taxAmount: number;
  rentalAmount: number;
  insuranceAmount: number;
  discountAmount: number;
  addonsAmount: number;
  totalPayableAmount: number;
  rentalTime: number;
  isProrated?: boolean;
  standardBillingDays?: number;
  proratedBillingDays?: number;
}

export interface IRefund {
  mode: string;
  amount: number;
  refundRef: string;
  status: string;
  currency?: string;
  id: string;
  paymentIntent?: string;
}

export interface IInsurancePolicyBooking {
  id: string;
  name: string;
  description: string;
}

export interface ITariffPriceRule {
  id: string;
  name: string;
}

export interface IDiscountInput {
  isPercent: boolean;
  code?: string;
  amount: number;
}

export interface IDiscount extends IDiscountInput {
  appliedBy: string;
  appliedDate: string;
}

export interface IAddressLocation {
  street: string;
  city: string;
  fullAddress: string;
  state: string;
  country: string;
  zipcode: string;
  location: IGeoLocation;
}

export interface IGeoLocation {
  type: string;
  latitude: number;
  longitude: number;
}

export interface ITax extends ITaxInput {
  appliedBy: string;
  appliedDate: string;
  payableAmount: number;
}

export interface ITaxInput {
  id?: string;
  amount: number;
  code: string;
  rate: string;
  type: string;
  subdivisionValue: number;
  subdivisionType: string;
  subdivisionRate: string;
}

export interface IInsuranceBooking {
  insuranceRate: number;
  excess: number;
  insurancePolicy?: IInsurancePolicyBooking;
}

export interface IAddonRequirement {
  addon: IAddonType;
  quantity: number;
  name: string;
  hasFixedRate: boolean;
  rate: number;
  payableAmount: number;
  displayName?: string;
  taxable?: boolean;
  tax: {
    title: string;
    value: number;
  };
}

export interface IBookingAddonType extends IAddonType {
  id: string;
}

export interface IBookingAddonRequirement extends IAddonRequirement {
  addon: IBookingAddonType;
}

export interface IAddonRequirementInput {
  addon: string;
  quantity: number;
  name: string;
  hasFixedRate: boolean;
  rate: number;
  displayName?: string;
  taxable?: boolean;
  tax?: {
    title: string | undefined;
    value: number | undefined;
  };
}

export interface IBookingVehicleGroup {
  name?: string;
  vehicleGroup: string | IBookingVehiclePriceGroup;
  vehicleIds: string[];
  vehicles?: [IVehicle];
  baseRate: number;
  applicableAmount?: number;
  priceRuleOperator?: string;
  priceRuleValueType?: ValueType;
  priceRuleTriggerType?: string;
  priceRuleAmount?: number;
  priceRuleName?: string;
  priceRule?: string;
  deposit: number;
  mileageLimit: number;
  unlimitedMileage: boolean;
  pricePerExtraMile: number;
  count: number;
  activeVehicleSchedules?: IBookingSchedule[];
}

export interface IVehicle {
  insurerCode: string;
  airConditioner: boolean;
  assetTag: string;
  batteries: any; //FIX: typing
  brakeHorsePower: string;
  activeVehicleDamages: INewVehicleDamage[];
  bluetooth: boolean;
  isGhostVehicle: boolean;
  bodyType: string;
  frogDiagram: VehicleBodyTypes;
  vehicleUniqueCode: string;
  vehicleUniqueId: string;
  co2Emissions: string;
  colour: string;
  cylinderCapacity: string;
  description: string;
  frontTyres: any; //FIX: typing
  fuelType: string;
  imported: boolean;
  infoSource: string;
  insuranceGroup: string;
  licencePlate: string;
  vin: string;
  make: string;
  model: string;
  manufactureDate: string;
  year: number;
  registrationDate: string;
  vehicleTest: boolean;
  vehicleTestDetails: string;
  vehicleTestDueDate: string;
  vehicleTestReports: any; //FIX: typing
  numberOfDoors: number;
  numberOfPreviousOwners: number;
  numberOfSeats: number;
  premium: boolean;
  price: string;
  msrp: string;
  rearTyres: any; //FIX: typing
  grossVehicleWeight: string;
  satNav: boolean;
  sixMonthRate: string;
  status?: string;
  steering: string;
  taxDetails: string;
  taxed: boolean;
  taxStatus: string;
  telemetryDeviceId: string;
  transmission: string;
  twelveMonthRate: string;
  typeApproval: string;
  tyreFitments: string[];
  vehicleDocuments: IVehicleDocument[];
  wheelPlan: string;
  acrissCode: string;
  branch: string;
  imageUrl: any;
  deleted?: boolean;
  id?: string;
  interiorColour: string;
  responseStatus?: number;
  bookingSchedules?: IBookingSchedule[];
  features: string[];
  vehicleState: IVehicleState;
  pcoNumber: string;
  services?: any; //FIX: typing
  holds?: IHold[];
  smartCarVehicleID?: string;
  telemetryDeviceData?: {
    avlEvent: IAvlEvent;
    stopHours: string;
    idleHours: string;
    driveHours: string;
  };
  telematicCoreData?: {
    latitude: number;
    longitude: number;
    address?: IAvlEventAddress;
    odometer: number;
    speed: number;
    eventTime?: string;
  };
  smartCarVehicleData?: Partial<ISmartCarVehicleData>;
  className?: string;
  activeBookingSchedule: IBookingSchedule;
  subscriptions: ISubscriptionInfo[];
  isCarShare?: boolean;
  selected?: boolean;
  vehicleType?: IVehicleType;
}

export enum IVehicleType {
  TWO_WHEELER = "TWO_WHEELER",
  ATV = "ATV",
  DEFAULT_VEHICLE = "DEFAULT_VEHICLE"
}

export interface ISmartCarVehicleData {
  vehicleLocation: ISmartcarLocation
  odometer: ISmartcarOdometer
  eVChargeStatus: ISmartcarChargeStatus
  eVBatteryLevel: ISmartcarBatteryLevel
  fuelLevel: ISmartcarFuelLevel
  engineOilLife: ISmartcarEngineOilLife
  tirePressure: ISmartcarTirePressure
  ISmartcarEVBatteryCapacity: ISmartcarEVBatteryCapacity
  mapRoutesData: IMapRouteData
}

interface ISmartcarLocation {
  latitude: number;
  longitude: number;
}

interface ISmartcarOdometer {
  distance: number;
}

interface ISmartcarEngineOilLife {
  lifeRemaining: number
}

interface ISmartcarTirePressure {
  frontLeft: number
  frontRight: number
  backLeft: number
  backRight: number
}


interface ISmartcarEVBatteryCapacity {
  capacity: number
}


interface ISmartcarBatteryLevel {
  range: number;
  percentRemaining: number
}

interface ISmartcarChargeStatus {
  state: string; // e.g. "FULLY_CHARGED"
  isPluggedIn: boolean
}


export interface ISmartcarFuelLevel {
  range: number;
  percentRemaining: number;
  amountRemaining: number;
}

export interface ISubscriptionInfo {
  subscription: Partial<ISubscription>;
  pricings: ISubscriptionPricing[];
  deposit: number;
}

export interface ISubscriptionPricing {
  duration: number;
  enrollmentAmount: number;
  subscriptionAmount: number;
  mileage: number;
  pricePerExtraMile: number;
  unlimitedMileage: boolean;
  swapsAllowed: number;
}

export interface ISubscription {
  id: string;
  name: string;
  rateTypeName: string;
  insurancePolicy: IInsurancePolicy;
  addons: IAddons[];
  features: string[];
  swapsTnC: string;
  serviceIncluded: boolean;
  serviceTnC: string;
  subscriptionTnC: string;
  vehicles: string[];
  isActive: boolean;
}

export interface ISubscriptionArgs {
  subscription: ISubscription;
  pricing: ISubscriptionPricing;
  deposit: number
}

export interface IInsurancePolicy {
  id: string;
  name: string;
  isActivated?: boolean;
}

export interface IAddons {
  id: string;
  name: string;
  displayName: string;
}

export interface IBookingSubscription {
  startDate: string;
  endDate: string;
  subscription: ISubscription;
  vehicle: IVehicle;
  duration: number;
  subscriptionAmount: number;
  enrollmentAmount: number;
  unlimitedMileage?: boolean;
  mileageLimit?: number;
  pricePerExtraMile?: number;
  swapsAllowed?: number;
}

export interface INewVehicleDamage {
  circumstance: string;
  bookingRef: string;
  reportDate?: string;
  incidentDate: string;
  reportedBy: string;
  frogDiagram: string;
  estimatedCost: number;
  status?: string;
  approvalStatus?: string;
  estimatedRepairDate: string;
  vehicleId: string;
  referenceNumber?: string;
  id?: string;
  type?: string;
  tenantId?: string;
  organisationId?: string;
  branchId?: string;
  damages: IDamage[];
  licencePlate?: string;
  bookingReferenceNumber?: string;
}

export interface IDamage {
  id?: string;
  title: string;
  descriptions: string;
  damageType: DamageTypes;
  damageKind: string;
  damageArea?: string;
  referenceNumber?: string;
  images: string[];
  location: {
    x: number;
    y: number;
  };
}
export enum DamageTypes {
  EXTERIOR = "exterior damage",
  INTERIOR = "interior damage",
}

export interface IVehicleState {
  id?: string;
  type?: string;
  defaultAccesories: IVehicleStateCheck[];
  generalChecks: IVehicleStateCheck[];
  bookedAccesories?: IVehicleStateBookedAccesory[];
  odometerReading: number;
  fuelStatus?: string;
  batteryStatus?: number;
  updatedAt?: string;
  updatedBy?: IUser;
  createdAt?: string;
  createdBy?: IUser;
}

export interface IVehicleService {
  id: string;
  vehicleId: string;
  serviceType: string;
  scheduleType: string;
  serviceProviderName: string;
  serviceProviderAddress: IAddress;
  appointmentDate: string;
  startDate: string;
  endDate: string;
  completionDate: string;
  odometerReading: number;
  description: string;
  totalExpenses: number;
  status: VehicleServiceStatus;
}

export enum VehicleServiceStatus {
  SCHEDULED = "SCHEDULED",
  NOT_STARTED = "NOT_STARTED",
  IN_SERVICE = "IN_SERVICE",
  DUE = "DUE",
  COMPLETED = "COMPLETED",
  CANCELLED = 'CANCELLED',
}

export interface IVehicleStateCheck {
  name: string;
  status: CheckListStatus;
  images: string[];
}

export enum CheckListStatus {
  "MISSING" = "MISSING",
  "CHECKED" = "CHECKED",
  "N/A" = "N/A",
}

export interface IVehicleStateBookedAccesory {
  name: string;
  type: string;
  quantity: number;
  description: string;
}

interface IAvlEvent {
  latitude: number;
  longitude: number;
  address?: IAvlEventAddress;
}

interface IAvlEventAddress {
  street: String;
  city: string;
}

interface IAvlEvent {
  latitude: number;
  longitude: number;
}

interface IHold {
  id: string;
  startDate: string;
  endDate: string;
}
export interface IVehicleDocument {
  id: string;
  title: string;
  startDate: string;
  endDate: string;
  licensingAuthority: string;
}

export interface VehicleCheckCustomerInfo {
  tncCheck: boolean;
  name: string;
  signature: string;
}

export interface IVehicleGroup {
  id?: string;
  bodyType: string;
  make: string;
  model: string;
  year: number;
  colour: string;
  fuelType: string;
  vehicleId?: string;
  licencePlate?: string;
  numberOfDoors: number;
  numberOfSeats: number;
  transmission: string;
  baseRate: IBaseRate[];
  deposit: number;
  mileageLimit: number;
  pricePerExtraMile: number;
  features: string[];
  imageUrl?: string;
}

export interface IBaseRate {
  baseRate: number;
  startDate: string;
  endDate?: string;
  priceRule?: string;
  priceRuleOperator?: string;
  priceRuleAmount?: number;
  priceRuleName?: string;
  priceRuleValueType?: ValueType;
  priceRuleTriggerType?: string;
}

export interface IAddonPayloadType {
  addonRequirements: IAddonRequirement[];
}

export interface IInvoicesPayloadType {
  invoices: IInvoice[];
}

export interface IQuoteUpdatePayloadType {
  referenceNumber: string;
  dateCreated: string;
}

export interface IDiscountPayloadType {
  discount: IDiscount;
}

export enum BookingPayloadTypes {
  BASE_DETAILS = "BASE_DETAILS",
  VEHICLE_SELECTION = "VEHICLE_SELECTION",
}

export interface IVehicleRequirementsPayload {
  __type: BookingPayloadTypes;
  vehicleRequirements: IVehicleGroup[];
}

export interface IDefinition {
  name: string;
  id: string;
}

export interface IFeature {
  name: string;
}

export interface IBookingCustomerDetails {
  customer: ICustomerDetails;
}

export interface ICustomerDetails {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  customerPCONumber?: string;
  gender?: string;
  phoneNumber: IPhoneNumberInput;
  dateOfBirth?: string;
  location: ILocation;
  license?: IlicenseDetails;
  approvedDrivers?: ICustomer[];
  address?: string;
  verificationMethod?: string;
}

export interface IPhoneNumberInput {
  phone: string;
  country: string;
}

export interface IlicenseDetails {
  licenseNumber?: string;
  dateOfExpiry?: string;
  authority?: string;
  endorsement?: string;
}

export interface IInsuranceAddon {
  policyId: string;
  policyName: string;
  description: string;
  rateName: string;
  rateDuration: number;
  rate: number;
  longTerm: boolean;
  excess: number;
}

export interface IPriceRule {
  id?: string;
  name: string;
  vehicleGroups: string[];
  customers: string[];
  serviceLocations: string[];
  operator: string;
  rules: ITriggerRule[];
  isActive: boolean;
}

export interface ITriggerRule {
  rateType: string;
  value: number;
  taxable?: boolean;
  dateRangeOptIn?: boolean;
  durationOptIn?: boolean;
  timeRangeOptIn?: boolean;
  pickUpDaysOptIn?: boolean;
  maximumBookingDuration?: number;
  minimumBookingDuration?: number;
  ruleEffectiveFromDate?: string;
  ruleEffectiveToDate?: string;
  valueType: string;
  mileageLimitOptIn?: boolean;
  unlimitedMileage?: boolean;
  mileageLimit?: number;
  pricePerExtraMile?: number;
  ruleEffectiveFromTime?: string;
  ruleEffectiveToTime?: string;
  days?: string[];
}

export interface ILocationSurchargeRules {
  id: string;
  name: string;
  value: number;
  rate: string;
  type: string;
  tax: {
    title: string;
    value: number;
  };
  payableAmount?: number;
  payableTaxAmount?: number;
}

export interface IConfirmationDialogData {
  description: string;
  isOpen: boolean;
  title: string;
  onCancel: () => void;
  onConfirm: () => void;
  confirmText?: string;
  cancelText?: string;
}

export interface ICreditNoteInput {
  invoiceRef: string;
  invoiceId: string;
  paidAmount: number;
  totalAmount: number;
  creditNoteType: string;
  creditNoteItems: ICreditNoteItem[];
}

export interface ICreditNoteItem {
  type: string;
  description: string;
  originalPrice: number;
  totalAmount: number;
  quantity: number;
  unitPrice: number;
  duration?: number;
}

export enum BookingStatus {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  CONFIRMED = "CONFIRMED",
  IN_PROGRESS = "IN_PROGRESS",
  QUOTE = "QUOTE",
  CONFIRMATION_IN_PROCESS = "CONFIRMATION_IN_PROCESS",
  DRAFT = "DRAFT",
  REQUIRES_APPROVAL = "REQUIRES_APPROVAL"
}
export interface IVehicleCheckoutPaymentInfo {
  paymentMethod: string;
  amount: number;
}

export enum BOOKING_SOURCE {
  B2B = "B2B",
  B2B2C = "B2B2C",
  B2B_MOBILE = "B2B_MOBILE",
  B2B2C_MOBILE = "B2B2C_MOBILE",
}

export enum ValueType {
  FIXED = "FIXED",
  VARIABLE = "VARIABLE",
}

export enum PriceRuleOperator {
  "SURGE" = "SURGE",
  "DISCOUNT" = "DISCOUNT",
}

export const BILLING_CYCLE_NAME = {
  HOURLY: "hourly",
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly",
};

export enum PDFViewMode {
  RENTAL_AGGREMENT = "RENTAL_AGGREMENT",
  VC_REPORT = "VC_REPORT",
}

export enum BookingType {
  RENTAL = "RENTAL",
  SUBSCRIPTION = "SUBSCRIPTION"
}

interface IVehicleBookings {
  bookingId: string;
  initialPickupDateTime: string;
  initialDropoffDateTime: string;
  bookingReferenceNumber: string;
  licencePlate: string;
  vehicleId: string;
}

export interface IDamage {
  id?: string;
  title: string;
  descriptions: string;
  damageType: DamageTypes;
  damageKind: string;
  damageArea?: string;
  referenceNumber?: string;
  images: string[];
  location: {
    x: number;
    y: number;
  };
}

export enum VehicleDamageStatus {
  FIXED = "FIXED",
  DUE = "DUE",
  OVERDUE = "OVERDUE",
}

export interface IDamageList {
  number: number;
  damageKind: string;
  images: string[];
}

export enum VehicleStatus {
  ACTIVE = "Active",
  OUT_ON_HIRE = "Out on Hire",
  AVAILABLE_FOR_HIRE = "Available_for_hire",
  IN_SERVICE = "In Service",
  DEACTIVATED = "Deactivated",
  ON_HOLD = "hold",
}

export enum SwapRequestStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED"
}

export interface ICreditNote {
  id: string;
  creditNoteRef: string;
  booking?: IBooking;
  invoice: IInvoice;
  creditNoteType: string;
  creditNoteItems: ICreditNoteItem[];
  allocations: ICreditNoteAllocation[];
  totalAmount: number;
  refundedAmount: number;
  availableAmount: number;
  refunds: IRefund[];
  createdAt: string;
  customer?: ICustomer;
  businessCustomer?: IBusinessCustomer;
  paymentsLinked: string[];
}

export interface ICreditNoteAllocation {
  invoice: IInvoice;
  amount: number;
  allocatedAt: string;
}