import { Button, CircularProgress, Dialog } from '@material-ui/core';
import React from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import styles from './verificationDialog.module.css';
import { IUser } from '../../reducers/auth/types';
import { ApolloError, useMutation } from '@apollo/client';
import { SEND_VERIFICATION_REQUEST } from '../../graphql/user/sendVerificationRequestMutation';
import { useSnackBar } from "../SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../SnackbarWrapper/SnackbarWrapper";
import { formatGraphQLErrorMessage } from '../utils';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { IDocumentType } from '../Profile';

interface IProps {
  open: boolean;
  handleClose: () => void;
  user: IUser | undefined;
  require: IDocumentType[]
}

export const VerificationDialog: React.FC<IProps> = (props) => {
  const snackbar = useSnackBar();
  const history = useHistory();
  const { handleClose, open, user } = props;
  let disabled = false;  
  if (!user?.customer?.profilePicture || !user?.customer?.license.images || !user?.customer?.license.images.length ) {
    disabled = true;
  }

  const [sendVerificationRequest, { loading }] = useMutation(SEND_VERIFICATION_REQUEST, {
    onCompleted: () => {
      snackbar({
        message: "Verification request has been successfully sent.",
        variant: SnackBarVariant.SUCCESS
      });
      handleClose();
    },
    onError: (error: ApolloError) =>
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      })
  });
  return (
    <Dialog maxWidth={"sm"} open={open} onClose={handleClose} >
      <div style={{ padding: 5, maxWidth: 400 }}>
        <h3 style={{ textAlign: "center" }}>Customer Verification</h3>
        <p style={{ padding: "0 20px" }}>Please provide the following additional details within the account section in order to create booking :-</p>
        <ul className={styles.listContainer}>
          <li style={{ display: "flex", alignItems: "center", gap: 4 }}>
            <span> {user?.customer?.profilePicture ? <CheckCircleOutlineIcon style={{ color: "green" }} /> : <HighlightOffIcon style={{ color: "red" }} />}</span>
            <span>Profile Photo</span>
          </li>
          <li style={{ display: "flex", alignItems: "center", gap: 4 }}>
            <span> {user?.customer?.license.images && user?.customer?.license.images?.length > 0 ? <CheckCircleOutlineIcon style={{ color: "green" }} /> : <HighlightOffIcon style={{ color: "red" }} />}</span>
            <span>Driving Licence</span>
          </li>
          {props.require.map((doc, index) => (
            <li key={index} style={{ display: "flex", alignItems: "center", gap: 4 }}>
              <span>
                {user?.customer?.documents?.some(d => d.documentType === doc.typeName) ? (
                  <CheckCircleOutlineIcon style={{ color: "green" }} />
                ) : (
                  <HighlightOffIcon style={{ color: "red" }} />
                )}
              </span>
              <span>{doc.typeName}</span>
            </li>
          ))}
        </ul>
        <p style={{ padding: "0 20px" }}>Once done, please submit your request for approval by clicking on the submit button below.</p>
        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={disabled}
            style={{
              background: "#3A3A3A",
              boxShadow: "none",
              fontSize: 12,
              marginBottom: 20
            }}
            onClick={() => {
              sendVerificationRequest({
                variables: {
                  customerId: user?.customer?.id
                }
              })
            }}
          >
            {loading ? <CircularProgress size={20} thickness={5} /> : "Submit"}
          </Button>
        </div>
      </div>
    </Dialog >
  )
}